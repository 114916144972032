import React from 'react'
import { scroller } from 'react-scroll'
import { Button, Head } from '@components'
import * as st404 from '@assets/styl/NotFound.module.styl'
import * as st from '@assets/styl/Success.module.styl'
import cn from 'classnames'

export default () => <>
  <Head path="/sucesso/" />

  <section className={cn(st404.core, st.core)}>
    <div className={st.grid}>
      <div className="icon-logo"></div>
      <h1>Sucesso!</h1>
      <h2>Sua mensagem foi enviada</h2>
      <p>Nós da Wecharge agradecemos por sua mensagem.<br/>Aguarde, estamos analisando para retornar com sua resposta.</p>
      <Button to="/" className={st404.btn} onClick={() => setTimeout(() => scroller.scrollTo('contato'), 300)}>Voltar</Button>
    </div>
  </section>
</>